<template>
    <v-row>
        <v-col sm="12" cols="12" class="grayBg px-6">
            <v-card color="white" class="pa-3 no_border" elevation="0">
                <v-row>
                    <v-col sm="9" cols="12" class="pt-0">
                        <h2 class="mb-0 font_20">{{ $t('sale_unit_items') }}</h2>
                    </v-col>
                    <v-col sm="3" cols="12" class="pt-0">
                        <v-dialog v-model="dialogM3" max-width="600px">
                            <template v-slot:activator="{ on }">
                                <v-btn color="primary" class="rounded-pill white--text float-right text-capitalize"
                                       v-on="on" @click="newClick">
                                    {{ $t('new_sale_unit') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>{{ $t('sale_unit') }}</v-card-title>
                                <v-icon class="btn_close" @click="dialogM3 = false">close</v-icon>
                                <v-card-text style="height: 210px; background-color: #EDF1F5; color: #333;">
                                    <v-row>
                                        <v-col sm="6" cols="12" class="pb-0">
                                            <label>{{ $t('categories') }}</label>
                                            <v-select class="mt-1"
                                                      outlined
                                                      tage="Categories"
                                                      v-model="mCategories"
                                                      :items="categories"
                                                      item-value="id"
                                                      item-text="name"
                                                      return-object
                                                      clearable/>

                                            <label>{{ $t('code') }}</label>
                                            <v-text-field class="mt-1" v-model="saleUnitItem.code" outlined clearable/>
                                        </v-col>
                                        <v-col sm="6" cols="12" class="pb-0">
                                            <label class="label">{{ $t('name') }}</label>
                                            <v-text-field class="mt-1" v-model="saleUnitItem.name" outlined clearable/>

                                            <label>{{ $t('description') }}</label>
                                            <v-textarea rows="4" class="mt-1" no-resize height="70px" tage="Description"
                                                        v-model="saleUnitItem.description"
                                                        outlined clearable/>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions class="pa-5">
                                    <v-row>
                                        <v-col sm="6" cols="6" class="py-0 text-left">
                                            <v-btn color="black" outlined
                                                   class=" text-capitalize rounded-pill black--text float-left"
                                                   @click="dialogM3 = false">{{ $t('cancel') }}
                                            </v-btn>
                                        </v-col>
                                        <v-col sm="6" cols="6" class="py-0 text-right">
                                            <v-btn color="primary" class="px-3 rounded-pill white--text text-capitalize"
                                                   @click="onSaveClose">
                                                {{ $t('save_close') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col sm="12" cols="12">
                        <LoadingMe
                            :isLoading="showLoading"
                            :fullPage="false"
                            type="loading"
                            :myLoading="true"/>
                        <template>
                            <kendo-datasource ref="gridSaleUnitItemDS"
                                              :data="saleUnitItemList"/>
                            <kendo-grid id="gridSaleUnitItem" class="grid-function"
                                        :data-source-ref="'gridSaleUnitItemDS'"
                                        :editable="false"
                                        :scrollable-virtual="true">
                                <kendo-grid-column
                                    :field="'no'"
                                    :title="$t('no')"
                                    :template="rowNumberTmpl"
                                    :width="80"
                                    :column-menu="false"
                                    :headerAttributes="{ style: 'background-color: #EDF1F5;', class: 'text-center'	}"
                                    :attributes="{style: 'text-align: center'}"/>
                                <kendo-grid-column
                                    :field="'code'"
                                    :title="$t('code')"
                                    :width="80"
                                    :template="'<span>#=code#</span>'"
                                    :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                <kendo-grid-column
                                    :field="'name'"
                                    :attributes="{class:'tb_name_td'}"
                                    :title="$t('name')"
                                    :width="60"
                                    :template="'<span>#=name#</span>'"
                                    :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                <kendo-grid-column
                                    :field="'category'"
                                    :title="$t('account')"
                                    :width="100"
                                    :template="'<span>#=category.name#</span>'"
                                    :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                <kendo-grid-column
                                    :field="'description'"
                                    :title="$t('description')"
                                    :width="100"
                                    :template="'<span>#=description?description:``#</span>'"
                                    :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                <kendo-grid-column
                                    :field="''"
                                    :title="$t('action')"
                                    :width="70"
                                    :command="[{ iconClass: 'k-icon k-i-edit', text: 'Edit', click: goEdit }]"
                                    :headerAttributes="{style: 'text-align: right; background-color: #EDF1F5'}"/>
                            </kendo-grid>
                        </template>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import SaleUnitItemModel from "@/scripts/model/SaleUnitItems"

const $ = require("jquery")
import kendo from "@progress/kendo-ui";

const saleUnitItemHandler = require("@/scripts/saleUnitItemHandler")
const saleUnitItemModel = new SaleUnitItemModel({})
const saleUCHandler = require("@/scripts/saleUCHandler")
export default {
    components: {
        LoadingMe: () => import(`@/components/Loading`)
    },
    data: () => ({
        showLoading: false,
        dialogM3: false,
        saleUnitItemList: [],
        categories: [],
        mCategories: {},
        saleUnitItem: saleUnitItemModel,
        schemaDefinition: {
            model: {
                id: "id"
            }
        },
    }),
    props: {},
    methods: {
        rowNumberTmpl(dataItem) {
            let ds = this.$refs.gridSaleUnitItemDS.kendoWidget(),
                index = ds.indexOf(dataItem);
            return index + 1;
        },
        async loadCategory() {
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved');
                    saleUCHandler.get().then(res => {
                        this.showLoading = false
                        this.categories = res
                        if (this.categories.length > 0) {
                            this.mCategories = this.categories[0]
                        }
                    })
                }, 300);
            })
        },
        cancel() {
            this.dialogM3 = false
        },
        close() {
            this.dialogM3 = false
        },
        async newClick() {
            await this.loadCategory()
            this.saleUnitItem = {}
            this.mCategories = {}
            this.dialogM3 = true
        },
        goEdit(e) {
            e.preventDefault()
            let grid = kendo.jQuery("#gridSaleUnitItem").data("kendoGrid")
            let dataItem = grid.dataItem($(e.currentTarget).closest("tr"))
            this.saleUnitItem = dataItem
            this.mCategories = dataItem.category
            this.dialogM3 = true
        },
        async onSaveClose() {
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved')
                    let data = {
                        id: this.saleUnitItem.id,
                        name: this.saleUnitItem.name,
                        code: this.saleUnitItem.code,
                        description: this.saleUnitItem.description,
                        category: this.mCategories,
                        status: 1,
                        type: this.saleUnitItem.id ? 'edit' : 'new'
                    }
                    saleUnitItemHandler.create(data).then(response => {
                        if (response.data.statusCode === 201) {

                            const res = response.data.data
                            const index = this.saleUnitItemList.findIndex(item => {
                                return (res.id === item.id)
                            })
                            if (index < 0) {
                                this.saleUnitItemList.push(res)
                            } else {
                                this.saleUnitItemList.splice(index, 1, res)
                            }
                            this.$snotify.success('Success')
                            this.close()
                            this.loadSaleUnitItems()
                        }
                    }).catch(e => {
                        this.$snotify.error('Something went wrong')
                        this.errors.push(e)
                    })
                })
            })
        },
        async loadSaleUnitItems() {
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved');
                    this.showLoading = true
                    saleUnitItemHandler.list().then(res => {
                        if (res.data.statusCode === 200)
                            this.showLoading = false
                        this.saleUnitItemList = res.data.data
                    })
                }, 300)
            })
        },
    },
    mounted: async function () {
        await this.loadSaleUnitItems()
    },
    created: async function () {
        await this.loadCategory()
    },
    computed: {},
};
</script>

<style scoped>

.function_footer {
    padding: 15px;
    display: inline-block;
}

p {
    color: rgba(0, 0, 0, 0.87);
}

.actionBtn {
    height: 47.2px !important;
}

.text_tip {
    font-size: 9px;
    line-height: 10px;
}

@media (max-width: 576px) {
}
</style>
